<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" centered
             :title="(valid ? 'Validation' : 'Refus') + ' de l\'inscription de ' + user.prenom + ' ' + user.nom">
        <b-textarea placeholder="Commentaire éventuel" v-model="commentaire" class="mb-4"/>

        <div class="text-center">
            <b-button variant="success" @click="submit">
                <font-awesome-icon icon="check"/>
                Confirmer
            </b-button>
            <b-button variant="danger" @click="hideModal">
                <font-awesome-icon icon="times"/>
                Annuler
            </b-button>
        </div>
    </b-modal>
</template>

<script>
    import {apiPath}            from '@/util/http';
    import {updateBadgesBureau} from '@/util/badge';
    import modalMixin           from '@/mixin/modalMixin';

    export default {
        name: "AdminUserValidate",
        mixins: [modalMixin],
        props: {
            user: {
                type: Object,
                required: true
            },
            valid: {
                type: Boolean,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            commentaire: null,
            modalRef: 'adminUserValidate'
        }),
        methods: {
            submit() {
                this.axios
                    .post(apiPath('admin_confirm_signup_user', {utilisateur: this.user.id}), {
                        valid: this.valid,
                        commentaire: this.commentaire
                    })
                    .then(() => {
                        this.$toaster.success('Utilisateur ' + (this.valid ? 'validé' : 'refusé') + ' avec succès');
                        updateBadgesBureau();
                        this.callback();
                        this.hideModal();
                    })
                    .catch(() => this.$toaster.error('Erreur lors de la confirmation'));
            }
        }
    }
</script>
